@import '../../../../scss/theme-bootstrap';

.gnav-country-language-chooser {
  display: flex;
  color: $white;
  vertical-align: middle;
  font-size: 15px;
  margin-top: 35px;
  margin-#{$ldirection}: 10px;
  &__country-section,
  &__language-section {
    position: relative;
    text-transform: none;
    width: auto;
    display: block;
    @include swap_direction(padding,2px 8px 0 10px);
    text-align: $ldirection;
    &:focus {
      outline: 0;
    }
  }
  &__country-selector,
  &__language-selector {
    color: $white;
    .icon {
      width: 17px;
      height: 17px;
      float: $rdirection;
      margin-top: 8px;
      margin-#{$ldirection}: 10px;
    }
    &.active {
      background-size: auto auto;
    }
  }
  &__switch-overlay {
    display: none;
  }
  .menu {
    display: none;
    #{$ldirection}: 0;
    position: absolute;
    border: 1px solid $color-light-gray;
    border-bottom: 0;
    width: 100%;
    max-height: 276px;
    background: $color-off-black;
    overflow: scroll;
    .site-header & {
      margin-#{$ldirection}: 0;
      margin-top: 0;
    }
  }
  .menu li {
    display: block;
    text-align: $ldirection;
    background: $color-off-black;
    .site-header & {
      display: block;
      margin-#{$rdirection}: 0;
    }
    a {
      display: block;
      border-bottom: 1px solid $color-light-gray;
      padding: 10px 18px;
      line-height: get-line-height(14px, 22px);
      color: $white;
      p {
        margin: 0;
      }
    }
  }
}

#cboxContent {
  .gnav-country-language-chooser {
    &__switch-overlay {
      display: inline;
      margin: 5px;
    }
    &__switch-button {
      display: flex;
      margin: 0 auto;
      padding: 5px;
      .column {
        padding-#{$rdirection}: 40px;
        width: 100%;
      }
      .button {
        float: $rdirection;
      }
    }
  }
  #cboxLoadedContent {
    padding-top: 50px;
  }
}
